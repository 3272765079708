import { Settings, HeadsetMic } from "@material-ui/icons";
import Home from "../../assets/images/icons/Home";
import Calender from "../../assets/images/icons/Calender";
import Receipt from "../../assets/images/icons/Receipt";
import LocalMall from "../../assets/images/icons/LocalMall";
import Store from "../../assets/images/icons/Store";
import PeopleAlt from "../../assets/images/icons/PeopleAlt";
import Layers from "../../assets/images/icons/Layers";
import Sms from "../../assets/images/icons/Sms";
import AccountBalance from "../../assets/images/icons/AccountBalance";
import InsertChart from "../../assets/images/icons/InsertChart";
import ListAlt from "../../assets/images/icons/ListAlt";
import AccountBalanceWallet from "../../assets/images/icons/AccountBalanceWallet";

const PanelRoutes = (ctx) => {
  return [
    {
      authorities: [0, 1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/dashboard",
      title: ctx.t(`menuItems.dashboard.title`),
      icon: Home,
      children: [],
    },
    {
      authorities: [0, 1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/appointments",
      limited_permission_path: "appointment",
      title: ctx.t(`menuItems.appointment.title`),
      icon: Calender,
      children: [],
    },
    {
      authorities: [0, 1, 2],
      includepackage: [1, 4],
      path: "/receipts",
      limited_permission_path: "sales",
      title: ctx.t(`menuItems.receipt.title`),
      icon: Receipt,
      children: [
        {
          to: "",
          label: ctx.t(`menuItems.receipt.children.index`),
          index: 60,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add",
          label: ctx.t(`menuItems.receipt.children.recordAdd`),
          index: 61,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add/costumeradd",
          label: ctx.t(`menuItems.receipt.children.newAdd`),
          index: 62,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 4],
      path: "/packages",
      limited_permission_path: "sales",
      title: ctx.t(`menuItems.packages.title`),
      icon: LocalMall,
      children: [
        {
          to: "/sales",
          label: ctx.t(`menuItems.packages.children.sales`),
          index: 9,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/sales/add",
          label: ctx.t(`menuItems.packages.children.salesAdd`),
          index: 11,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add",
          label: ctx.t(`menuItems.packages.children.add`),
          index: 10,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/list",
          label: ctx.t(`menuItems.packages.children.list`),
          index: 8,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/unbooked",
          label: ctx.t(`menuItems.packages.children.unbookedPackages`),
          index: 33,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 4],
      path: "/products",
      limited_permission_path: "sales",
      title: ctx.t(`menuItems.products.title`),
      icon: Store,
      children: [
        {
          to: "",
          label: ctx.t(`menuItems.products.children.index`),
          index: 12,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add",
          label: ctx.t(`menuItems.products.children.add`),
          index: 13,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [0, 1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/customers",
      limited_permission_path: "customer",
      title: ctx.t(`menuItems.customers.title`),
      icon: PeopleAlt,
      children: [
        {
          to: "",
          label: ctx.t(`menuItems.customers.children.active`),
          index: 1,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add",
          label: ctx.t(`menuItems.customers.children.add`),
          index: 4,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add/bulk",
          label: ctx.t(`menuItems.customers.children.addBulk`),
          index: 5,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/risky",
          label: ctx.t(`menuItems.customers.children.risky`),
          index: 2,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/passive",
          label: ctx.t(`menuItems.customers.children.passive`),
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/rating",
          label: ctx.t(`menuItems.customers.children.rating`),
          index: 3,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/services",
      limited_permission_path: "services",
      title: ctx.t(`menuItems.services.title`),
      icon: Layers,
      children: [
        {
          to: "",
          label: ctx.t(`menuItems.services.children.index`),
          index: 6,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/add",
          label: ctx.t(`menuItems.services.children.add`),
          index: 7,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/requests",
          label: ctx.t(`menuItems.services.children.requests`),
          index: 42,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/bounty",
          label: ctx.t(`menuItems.services.children.bounty`),
          index: 43,
          includepackage: [1, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/sms",
      limited_permission_path: "sms",
      title: ctx.t(`menuItems.sms.title`),
      icon: Sms,
      children: [
        {
          to: "/",
          label: ctx.t(`menuItems.sms.children.sendGroup`),
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/send/selective",
          label: ctx.t(`menuItems.sms.children.sendSelective`),
          index: 26,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/send/filter",
          label: ctx.t(`menuItems.sms.children.sendFilter`),
          index: 27,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/reports",
          label: ctx.t(`menuItems.sms.children.index`),
          index: 28,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/blacklist",
          label: ctx.t(`menuItems.sms.children.blacklist`),
          index: 33,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 4],
      path: "/accounting",
      limited_permission_path: "income_expense",
      title: ctx.t(`menuItems.accounting.title`),
      icon: AccountBalance,
      children: [
        {
          to: "/income",
          label: ctx.t(`menuItems.accounting.children.income`),
          index: 14,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/expense",
          label: ctx.t(`menuItems.accounting.children.expense`),
          index: 15,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/debt",
          label: ctx.t(`menuItems.accounting.children.debt`),
          index: 17,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/bounty",
          label: ctx.t(`menuItems.accounting.children.bounty`),
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/expense/types",
          label: ctx.t(`menuItems.accounting.children.expenseTypes`),
          index: 16,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/credit",
          label: ctx.t(`menuItems.accounting.children.credit`),
          index: 29,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/debit",
          label: ctx.t(`menuItems.accounting.children.debit`),
          index: 72,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 4],
      path: "/reports",
      limited_permission_path: "income_expense",
      title: ctx.t(`menuItems.reports.title`),
      icon: InsertChart,
      children: [
        {
          to: "/company",
          label: ctx.t(`menuItems.reports.children.company`),
          index: 21,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/service",
          label: ctx.t(`menuItems.reports.children.service`),
          index: 24,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/stock",
          label: ctx.t(`menuItems.reports.children.stock`),
          index: 25,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/staff",
          label: ctx.t(`menuItems.reports.children.staff`),
          index: 22,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/customer",
          label: ctx.t(`menuItems.reports.children.customer`),
          index: 23,
          includepackage: [1, 2, 3, 4],
        },
        {
          to: "/appointment",
          label: ctx.t(`menuItems.reports.children.appointment`),
          index: 26,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [0, 1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/todos",
      title: ctx.t(`menuItems.todos.title`),
      icon: ListAlt,
      children: [],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/wallet",
      limited_permission_path: "wallet",
      title: ctx.t(`menuItems.wallet.title`),
      icon: AccountBalanceWallet,
      children: [],
    },

    {
      authorities: [1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/settings",
      limited_permission_path: "system",
      title: ctx.t(`menuItems.settings.title`),
      icon: Settings,
      children: [
        {
          to: "/",
          label: ctx.t(`menuItems.settings.children.index`),
          index: 30,
          includepackage: [1, 2, 3, 4],
        },

        {
          to: "/enterprise-gallery",
          label: ctx.t(`menuItems.gallery.children.enterprise_gallery`),
          includepackage: [1, 2, 3, 4],
        },

        {
          to: "/sms",
          label: "SMS - WhatsApp Ayarları",
          index: 34,
          includepackage: [1, 2, 3, 4],
        },

        {
          to: "/agreement/index",
          label: ctx.t(`menuItems.agreement.settings`),
          includepackage: [4],
        },
        {
          to: "/logs",
          label: ctx.t(`menuItems.settings.children.logs`),
          index: 34,
          includepackage: [1, 2, 3, 4],
        },

        {
          to: "/give-referance",
          label: ctx.t(`menuItems.settings.children.giveAReferans`),
          index: 38,
          includepackage: [1, 2, 3, 4],
        },
      ],
    },
    {
      authorities: [1, 2],
      includepackage: [1, 2, 3, 4],
      path: "/support/live-support",
      title: ctx.t(`menuItems.support.title`),
      icon: HeadsetMic,
      children: [],
    },
  ];
};

export default PanelRoutes;
