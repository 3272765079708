import React from "react";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Input from "../../../theme/CustomMUI/Input";
import { Column, Grid } from "../../../theme/Grid";
import styled from "styled-components";
import Axios from "axios";
import moment from "moment";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import { Alert } from "@material-ui/lab";
import Table from "../../../theme/Table";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";

class LiveSupport extends React.Component {
  static contextType = AppContext;

  state = {
    loaded: false,
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.getLiveSupports(page),
    },
    support_about: null,
    support_detail: null,
    name: null,
    surname: null,
    phone: null,
    date: new Date(),
    hour: null,

    liveSupports: [],
    available_hours: [],
  };

  getAvailableHours = () => {
    Axios.post(
      `${this.context.api_endpoint}/company/live/support/available/hours`,
      {
        date: moment(this.state.date).format("YYYY-MM-DD"),
      }
    ).then((response) => {
      if (response.data.data) {
        this.setState({
          available_hours: [...response.data.data],
        });
      }
    });
  };

  createLiveSupport = () => {
    this.validateLiveSupport() &&
      Axios.post(`${this.context.api_endpoint}/company/live/support/create`, {
        title: this.state.support_about,
        description: this.state.support_detail,
        name: `${this.state.name} ${this.state.surname}`,
        phone: this.state.phone,
        appointment_date: `${moment(this.state.date).format("YYYY-MM-DD")}T${
          this.state.hour
        }:00Z`,
      }).then((response) => {
        toast.success(
          this.context.t(`[support/live_support].createLiveSupportRequestToast`)
        );
        this.setState({
          support_about: null,
          support_detail: null,
          name: null,
          surname: null,
          phone: null,
          date: new Date(),
          hour: null,
        });
        this.getLiveSupports();
      });
  };

  getLiveSupports = (page = 1) => {
    Axios.get(
      `${this.context.api_endpoint}/company/live/support/list?page=${page}`
    )
      .then((response) => {
        this.setState({
          loaded: true,
          pagination: {
            ...this.state.pagination,
            page: response.data.data.page,
            total_page: response.data.data.total_page,
          },
          liveSupports: [
            ...response.data.data.records.map((request) => {
              return {
                ...request,
                appointment_date: moment(request.appointment_date).format(
                  "DD/MM/YYYY HH:mm"
                ),
              };
            }),
          ],
        });
      })
      .catch((e) => {
        if (e.response.data.code === 401) {
          this.props.history.push("/wallet");
        }
      });
  };

  cancelLiveSupportRequest = (id) => {
    Axios.post(`${this.context.api_endpoint}/company/live/support/cancel`, {
      id,
    }).then((response) => {
      toast.success(
        this.context.t(`[support/live_support].cancelLiveSupportRequestToast`)
      );
      this.getLiveSupports();
    });
  };

  componentDidMount() {
    this.getAvailableHours();
    this.getLiveSupports();
  }

  render() {
    return (
      <AuthContainer>
        <Grid>
          <Column className="xs-12 sm-12 md-12">
            <h2>
              {this.context.t(`[support/live_support].liveSupportRequestTitle`)}
            </h2>
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <Input
              required
              label={this.context.t(`[support/live_support].nameInputLabel`)}
              value={this.state.name ?? ""}
              placeholder={this.context.t(
                `[support/live_support].nameInputHint`
              )}
              onChange={(e) =>
                this.setState({
                  name: e.target.value,
                })
              }
            />
            <Input
              required
              label={this.context.t(`[support/live_support].surnameInputLabel`)}
              value={this.state.surname ?? ""}
              placeholder={this.context.t(
                `[support/live_support].surnameInputHint`
              )}
              onChange={(e) =>
                this.setState({
                  surname: e.target.value,
                })
              }
            />
            <MaskedInput
              required
              label={this.context.t(
                `[support/live_support].phoneNumberInputLabel`
              )}
              maskChar={""}
              mask="999 999 9999"
              startAdornment="0"
              value={this.state.phone ?? ""}
              placeholder="500 000 0000"
              onChange={(e) =>
                this.setState({
                  phone: e.target.value,
                })
              }
            />
            <Input
              required
              label={this.context.t(
                `[support/live_support].supportRequestAboutLabel`
              )}
              value={this.state.support_about ?? ""}
              placeholder={this.context.t(
                `[support/live_support].supportRequestAboutHint`
              )}
              onChange={(e) =>
                this.setState({
                  support_about: e.target.value,
                })
              }
            />
            <Input
              required
              label={this.context.t(
                `[support/live_support].supportRequestDetailLabel`
              )}
              value={this.state.support_detail ?? ""}
              placeholder={this.context.t(
                `[support/live_support].supportRequestDetailHint`
              )}
              hoverHelperText={this.context.t(
                `[support/live_support].supportRequestDetailHelperText`
              )}
              multiline={true}
              rows={7}
              onChange={(e) =>
                this.setState({
                  support_detail: e.target.value,
                })
              }
            />
          </Column>
          <Column className="xs-12 sm-12 md-6">
            <h3>{this.context.t(`[support/live_support].appointmentDate`)}</h3>
            <DatePickerInput
              className="mb-2"
              label={this.context.t(`[support/live_support].dateInputLabel`)}
              value={this.state.date}
              format="DD MMMM dddd YYYY"
              onChange={async (selected_date) => {
                await this.setState({ date: selected_date });
                this.getAvailableHours();
                this.setState({
                  hour: null,
                });
              }}
            />
            <HoursContainer className="mb-2">
              {this.state.available_hours.length > 0 ? (
                this.state.available_hours.map((hour) => (
                  <HourButton
                    key={hour}
                    className={`${
                      this.state.hour !== hour
                        ? "bg-blue-opacity fc-blue"
                        : "bg-primary fc-white"
                    }`}
                    style={{ margin: "5px" }}
                    onClick={() => {
                      this.setState({
                        hour: hour,
                      });
                    }}
                  >
                    {hour}
                  </HourButton>
                ))
              ) : (
                <p>
                  {this.context.t(`[support/live_support].noAvailableHours`)}
                </p>
              )}
            </HoursContainer>
            <Alert severity="info" className="mb-2">
              <b>
                {this.context.t(`[support/live_support].alertInfo.text1`)}{" "}
                <a href="https://www.alpemix.com/site/alpemix.exe">
                  {" "}
                  {this.context.t(`[support/live_support].alertInfo.text2`)}
                </a>{" "}
                {this.context.t(`[support/live_support].alertInfo.text3`)}{" "}
                <b>
                  {this.context.t(`[support/live_support].alertInfo.text4`)}
                </b>{" "}
                {this.context.t(`[support/live_support].alertInfo.text5`)}
              </b>
            </Alert>
            <Alert severity="warning" className="mb-2">
              <b>
                {this.context.t(
                  `[support/live_support].appointmentCancelDescription`
                )}
              </b>
            </Alert>
            <Button
              title={this.context.t(
                `[support/live_support].createAppointmentButtonTitle`
              )}
              backgroundColor="primary"
              textColor="white"
              fullWidth={true}
              onClick={() => {
                this.createLiveSupport();
              }}
            />
          </Column>
        </Grid>
        <Grid>
          <Column className="xs-12 sm-12 md-12">
            <h2>
              {this.context.t(`[support/live_support].appointmentRequestTitle`)}
            </h2>
          </Column>
          <Column className="xs-12 sm-12 md-12">
            <Table
              refreshAction={() => this.getLiveSupports()}
              loaded={this.state.loaded}
              headings={{
                appointment_date: {
                  label: this.context.t(
                    `[support/live_support].headings.appointmentDate`
                  ),
                  style: { width: 170 },
                },
                title: {
                  label: this.context.t(
                    `[support/live_support].headings.appointmentAbout`
                  ),
                },
                description: {
                  label: this.context.t(
                    `[support/live_support].headings.appointmentDetail`
                  ),
                  limited_line: 1,
                  style: { width: 300 },
                },
                name: {
                  label: this.context.t(`[support/live_support].headings.name`),
                },
                phone: {
                  label: this.context.t(
                    `[support/live_support].headings.phoneNumber`
                  ),
                },
                status: {
                  label: this.context.t(
                    `[support/live_support].headings.appointmentStatus`
                  ),
                },
                _: { label: this.context.t(`component.actionHeadingText`) },
              }}
              rows={this.state.liveSupports}
              pagination={this.state.pagination}
              replacements={{
                status: {
                  0: this.context.t(
                    `[support/live_support].headings.replacements.status.waiting`
                  ),
                  1: this.context.t(
                    `[support/live_support].headings.replacements.status.done`
                  ),
                  2: this.context.t(
                    `[support/live_support].headings.replacements.status.cancel`
                  ),
                },
              }}
              buttons={[
                {
                  title: this.context.t(
                    `[support/live_support].headings.cancelButtonTitle`
                  ),
                  icon: "close",
                  textColor: "red",
                  disabled: (row) => !!row.status,
                  onClick: (row) => this.cancelLiveSupportRequest(row.id),
                },
              ]}
            />
          </Column>
        </Grid>
      </AuthContainer>
    );
  }

  validateLiveSupport = () => {
    return Validate([
      {
        field: "İsim",
        value: this.state.name,
        condition: /.+/,
      },
      {
        field: "Soyisim",
        value: this.state.surname,
        condition: /.+/,
      },
      /* {
        field: "Telefon Numarası",
        value: this.state.phone,
        condition: /\S{10}/,
      }, */
      {
        field: "Destek Talebi Konusu",
        value: this.state.support_about,
        condition: /.+/,
      },
      {
        field: "Destek Talebi Ayrıntısı",
        value: this.state.support_detail,
        condition: /.+/,
      },
      {
        field: "Saat",
        value: this.state.hour,
        condition: /.+/,
      },
    ]);
  };
}

export default LiveSupport;

const HoursContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items-center;
  gap: 0.3rem;
`;

const HourButton = styled.button`
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: white;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 0px 2px grey;
`;
