import React, { Component } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import Axios from "axios";
import moment from "moment";
import AuthContainer from "../../../components/AuthContainer";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import Input from "../../../theme/CustomMUI/Input";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import ConvertTimeToZulu from "../../../functions/ConvertTimeToZulu";
import Loader from "../../../assets/images/circular.gif";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";

class LogsIndex extends Component {
  static contextType = AppContext;

  state = {
    typingTimeout: 1000,
    loaded: false,
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => this.searchSystemLogs(page),
    },
    data: [],

    // search
    filter_expanded: false,
    selected_customer: false,
    search: {
      between: {
        end: ConvertTimeToZulu(moment().unix()),
        start: ConvertTimeToZulu(moment().subtract(30, "days").unix()),
      },
      offset: 30,
      is_between: false,
      text: "",
      // user_id: null, // nullable
    },

    // customer filter autocomplete
    specificCustomer: {
      data: [],
      pagination: {
        total_page: 0,
        page: 0,
      },
    },
    categoryList: [
      {
        id: 1,
        name: "Tüm Kayıtlar",
        type: null,
      },
      {
        id: 2,
        name: "Danışan Kayıtları",
        type: 1,
      },
      {
        id: 3,
        name: "Adisyon Kayıtları",
        type: 2,
      },
      {
        id: 4,
        name: "Personel Kayıtları",
        type: 3,
      },
      {
        id: 5,
        name: "Randevu Kayıtları",
        type: 4,
      },
      {
        id: 6,
        name: "SMS Ayarları Kayıtları",
        type: 5,
      },
    ],
    selected_category: null,
  };

  searchSystemLogs = async (page = 1) => {
    /*
      Between    struct {
        End   time.Time `json:"end"` //zulu
        Start time.Time `json:"start"` //zulu
      } `json:"between"`
      Offset    uint8          `json:"offset"`
      UserID    *uint          `json:"user_id"` // Null gönderilebilir. Spesifik olarak bi kullanıcı üzerinde arama yapılması gerekirse bu alanda gereken değer gönderilmeli.
      Text      string         `json:"text"`
    */

    await this.setState({ loaded: false });
    const { search } = this.state;
    let end_of = `${moment(search.between.end).format(
      "YYYY-MM-DD"
    )}T23:59:59.000Z`;
    Axios.post(`${this.context.api_endpoint}/company/logs?page=${page}`, {
      type: this.context.state?.log_type,
      vid: this.context.state?.log_id,
      ...(this.state.filter_expanded && {
        between: {
          end: end_of,
          start: search.between.start,
        },
        offset: search.offset,
        is_between: search.is_between,
        text: search.text,
      }),
    })

      .then((response) => {
        const _data = response.data.data;

        // console.log(_data);

        if (response.status === 200) {
          this.setState({
            loaded: true,
            data: _data.records.map((item) => {
              let message_detail = item.message
                ?.split("\n")
                .filter((_, index) => index !== 0)
                .map((item) => item)
                .join("\n");

              return {
                ...item,
                created_at: moment(item.created_at).format("LLL"),
                message: item.message?.split("\n")[0],
                message_detail,
              };
            }),

            pagination: {
              ...this.state.pagination,
              page: _data.page,
              total_page: _data.records.length,
              // onChange: page => this.getSystemLogs(page)
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e?.response?.data?.message);
          this.setState({ loaded: true });
        }
      });
  };

  updateSearchState = async (additional) => {
    return (
      ((await this.setState({
        search: {
          ...this.state.search,
          ...additional,
        },
      })) &&
        true) ||
      false
    );
  };

  searchCustomerByKey = async (page = 1, key) => {
    await Axios.get(
      `${this.context.api_endpoint}/company/customers?page=${page}`,
      {
        params: { key },
      }
    ).then(({ data }) => {
      this.setState({
        specificCustomer: {
          data: data.data.records,
          pagination: {
            ...this.state.specificCustomer.pagination,
            total_page: data.data.records.length,
            page: data.data.page,
          },
        },
      });
    });
  };

  componentDidMount() {
    // console.log("Token::");
    // console.log(Axios.defaults.headers.Authorization);
    this.searchSystemLogs();
    // this.getCustomers();
  }
  componentDidUpdate(prevProps, prevState) {
    // Önceki durum ile mevcut durumu karşılaştırın
    if (this.state.selected_category !== prevState.selected_category) {
      // customer_state değiştiğinde myFunc fonksiyonunu çağırın
      this.searchSystemLogs();
    }
  }

  render() {
    const { loaded } = this.state;
    return (
      <AuthContainer authorities={[1]} limited_permission="system">
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-auto">
            <FormControlLabel
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{this.context.t(`[logs/index].filter.title`)}</span>
                </FCLabel>
              }
              control={
                <Switch
                  color="primary"
                  checked={this.state.filter_expanded}
                  onChange={async (e) => {
                    await this.setState({ filter_expanded: e.target.checked });
                    await this.searchSystemLogs();
                  }}
                />
              }
            />

            {this.state.filter_expanded && (
              <Grid className="pb-4">
                <Column className="xs-12 sm-12 md-12 lg-4">
                  <Input
                    className="mb-0"
                    label={this.context.t(
                      `[logs/index].filter.form.description`
                    )}
                    onChange={async (e) => {
                      const { value } = e.target;

                      // console.log('işlem açıklaması: ', value);

                      if (this.state.typingTimeout)
                        clearTimeout(this.state.typingTimeout);
                      value
                        ? await this.updateSearchState({
                          text: value.trim(),
                        })
                        : await this.updateSearchState({
                          text: "",
                        });
                      await this.setState({
                        typingTimeout: setTimeout(async () => {
                          await this.searchSystemLogs();
                        }, 500),
                      });
                      // await this.setState({ loaded: false});
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <Select
                    label="Kayıt Kategorileri"
                    items={this.state.categoryList}
                    labelKey="name"
                    valueKey="id"
                    selected={
                      this.state.selected_category
                        ? this.state.selected_category.id
                        : null
                    }
                    returnFullObject
                    handler={async (filterPicker) => {
                      this.setState({
                        selected_category: filterPicker,
                      });
                      this.context.dispatch({
                        type: "LOG_TYPE",
                        payload: { log_type: filterPicker.type },
                      });
                      this.context.dispatch({
                        type: "LOG_ID",
                        payload: {
                          log_id: null,
                        },
                      });
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DatePickerInput
                    label={this.context.t(
                      `['logs/index'].filter.form.startDate`
                    )}
                    disabled={!this.state.search.is_between}
                    value={
                      new Date(
                        moment(this.state.search.between.start).toISOString()
                      )
                    }
                    onChange={async (start_date) => {
                      await this.updateSearchState({
                        between: {
                          start: ConvertTimeToZulu(moment(start_date).unix()),
                          end: this.state.search.between.end,
                        },
                        // offset: OffsetFinder(start_date, this.state.search.between.end)
                      });
                      await this.searchSystemLogs();
                    }}
                  />
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-2">
                  <DatePickerInput
                    label={this.context.t(`['logs/index'].filter.form.endDate`)}
                    disabled={!this.state.search.is_between}
                    value={
                      new Date(
                        moment(this.state.search.between.end).toISOString()
                      )
                    }
                    onChange={async (end_date) => {
                      await this.updateSearchState({
                        between: {
                          start: this.state.search.between.start,
                          end: ConvertTimeToZulu(moment(end_date).unix()),
                        },
                        // offset: OffsetFinder(end_date, this.state.search.between.start)
                      });
                      await this.searchSystemLogs();
                      // await this.getFilterByAppointmentsStatistics();
                    }}
                  />
                </Column>

                <Column className="xs-12 sm-12 md-12 lg-2">
                  <FormControlLabel
                    className="mt-1"
                    label={
                      <span>
                        {this.context.t(`[logs/index].filter.form.allDate`)}
                      </span>
                    }
                    control={
                      <Switch
                        color="primary"
                        checked={!this.state.search.is_between}
                        onChange={async (e) => {
                          await this.updateSearchState({
                            is_between: !e.target.checked,
                          });
                          await this.searchSystemLogs();
                        }}
                      />
                    }
                  />
                </Column>
              </Grid>
            )}
          </Column>
        </Grid>

        <Grid>
          {!loaded ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "5vh",
                width: "100%",
              }}
            >
              <img src={Loader} height={70} />
            </div>
          ) : (
            <Column className="xs-12 sm-12">
              <Table
                refreshAction={() =>
                  this.searchSystemLogs(this.state.pagination.page)
                }
                loaded={this.state.loaded}
                skeletonCount={10}
                headings={{
                  created_at: {
                    label: this.context.t(
                      `['settings/logs'].headings.createdAt`
                    ),
                    style: { width: 200 },
                  },
                  message: {
                    label: this.context.t(`['settings/logs'].headings.message`),
                    style: { width: 380 },
                  },
                  message_detail: {
                    label: this.context.t(`['settings/logs'].headings.detail`),
                    style: { whiteSpace: "pre-wrap" },
                  },
                  ip: {
                    label: this.context.t(`['settings/logs'].headings.ip`),
                  },
                }}
                pagination={this.state.pagination}
                rows={this.state.data}
              />
            </Column>
          )}
        </Grid>
      </AuthContainer>
    );
  }
}

export default LogsIndex;
