import React, { useContext } from "react";
import AppContext from "../../../../context/store";
import { Tooltip } from "@material-ui/core";
import { BsInfoSquareFill } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { AiOutlineBarcode } from "react-icons/ai";
import { BsWhatsapp } from "react-icons/bs";
import { RiExternalLinkFill } from "react-icons/ri";
import { FaCommentSms } from "react-icons/fa6";
import { BsInfoCircle } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";
import { TiPlusOutline } from "react-icons/ti";
import { FaPlus } from "react-icons/fa";

function PlusGoldDetail({ selectLicenceType, packages, extras }) {
  const context = useContext(AppContext);
  return (
    <div>
      <Tooltip
        key={1}
        title={context.t(`wallet.whatsapp_extra_package_tooltip`)}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        placement="bottom"
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px solid #f2f2f2",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
            }}
          >
            {extras && (
              <GiCheckMark
                style={{
                  color: "rgb(0, 191, 99)",
                  transition: "color 0.2s ease",
                }}
                size={14}
              />
            )}{" "}
            <BsWhatsapp size={20} color="green" />
            <div> WhatsApp Entegrasyonu</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div>
              {" "}
              ₺{packages.filter((m) => m.name === "whatsapp")[0]?.discounted}
            </div>
          </div>
        </div>
      </Tooltip>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "8px",
        }}
      >
        <FaPlus />
      </div>
      <Tooltip
        key={2}
        title={context.t(`wallet.online_link_extra_package_tooltip`)}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        placement="bottom"
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
            border: "1px solid #f2f2f2",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
            }}
          >
            {extras && (
              <GiCheckMark
                style={{
                  color: "rgb(0, 191, 99)",
                  transition: "color 0.2s ease",
                }}
                size={14}
              />
            )}
            <RiExternalLinkFill size={20} color="blue" />
            <div>Randevu Katılımı İçin Link</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div>
              {" "}
              ₺{packages.filter((m) => m.name === "onlineLink")[0]?.discounted}
            </div>
          </div>
        </div>
      </Tooltip>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "8px",
        }}
      >
        <FaPlus />
      </div>

      <Tooltip
        key={3}
        title={"1000 SMS-WhatsApp Hediye!"}
        arrow
        enterTouchDelay={0}
        leaveTouchDelay={3000}
        placement="bottom"
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "10px",
            border: "1px solid #f2f2f2",
            padding: "10px",
            borderRadius: "10px",
            boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "6px",
              alignItems: "center",
            }}
          >
            {extras && (
              <GiCheckMark
                style={{
                  color: "rgb(0, 191, 99)",
                  transition: "color 0.2s ease",
                }}
                size={14}
              />
            )}{" "}
            <FaCommentSms size={16} />
            <div>1000 SMS - WhatsApp</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <div>
              ₺
              {
                packages.filter((m) => m.name === "goldPlusGiftSms")[0]
                  ?.discounted
              }
            </div>
          </div>
        </div>
      </Tooltip>
      {selectLicenceType === "platinium" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "8px",
          }}
        >
          <FaPlus />
        </div>
      )}
      <>
        {selectLicenceType === "platinium" && (
          <Tooltip
            title={context.t(`wallet.bardcode_extra_package_tooltip`)}
            arrow
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            placement="bottom"
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
                border: "1px solid #f2f2f2",
                padding: "10px",
                borderRadius: "10px",
                boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "6px",
                  alignItems: "center",
                }}
              >
                {extras && (
                  <GiCheckMark
                    style={{
                      color: "rgb(0, 191, 99)",
                      transition: "color 0.2s ease",
                    }}
                    size={14}
                  />
                )}
                <AiOutlineBarcode size={16} />
                <div>Barkod Okuyucu</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <div>
                  ₺{packages.filter((m) => m.name === "barcode")[0]?.discounted}
                </div>
              </div>
            </div>
          </Tooltip>
        )}
      </>
    </div>
  );
}

export default PlusGoldDetail;