import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../context/store";
import AuthContainer from "../../components/AuthContainer";
import { withRouter } from "react-router-dom";
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "../../theme/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Input from "../../theme/CustomMUI/Input";
import Axios from "axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import ValidateLogin from "../../functions/ValidateLogin";
import SmartBanner from "../../components/SmartBanner";
import styled from "styled-components";
import SinglePlan from "../../components/SinglePlan";
import AuthWithToken from "../../functions/AuthWithToken";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import AppQR from "../../assets/images/salonrandevu_qr.png";
import { GoogleButton } from "react-google-button";
import { auth, providerApple, providerGoogle } from "./Firebase";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import googleIcon from "../../assets/images/googleIcon.png";
import appleIcon from "../../assets/images/appleIcon.png";
// import LanguageButton from "../../components/Buttons/LanguageButton";
// import { LanguagesContainer } from "../../components/Scaffold/TranslateView";
// import LanguageButton from "../../components/Buttons/LanguageButton";
// import { LanguagesContainer } from "../../components/Scaffold/TranslateView";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${require("../../assets/images/unauth_image_en.jpg")})`, // unauth.mockup
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(14, 4, 6),
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 5),
  },
}));

const LoginIndex = (props) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [form, setForm] = useState({
    mail: null,
    password: null,
    auths: {
      googleAuth: {
        key: null,
      },
      appleAuth: {
        key: null,
      },
    },
  });

  const [license_ending, setLicenseEnding] = useState({
    modal: false,
    company: null,
  });

  const [rememberMe, setRememberMe] = useState(false);

  const clearLocalStorage = () => {
    const dashboardOpenTutorial = localStorage.getItem("open_tutorial");
    localStorage.clear();
    dashboardOpenTutorial !== null &&
      localStorage.setItem("open_tutorial", "1");
  };

  useEffect(() => {
    if (window.location.href.includes("mail")) {
      (async () => {
        await LoginFunc({
          mail: `${window.location.href.match(/=(.*)/)[1]}`,
          password: "karpuz",
        });
      })();
    }
    const email = localStorage.getItem("remember_me_email");
    setRememberMe(email ? true : false);
    email && setForm({ ...form, mail: email });
    // eslint-disable-next-line
  }, []);

  // sing in with google
  const signInWithGoogle = () => {
    setForm((prevForm) => ({
      ...prevForm,
      mail: null,
      password: null,
    }));
    signInWithPopup(auth, providerGoogle)
      .then((result) => {
        console.log(result);
        setForm((prevForm) => ({
          ...prevForm,
          auths: {
            ...prevForm.auths,
            googleAuth: {
              ...prevForm.auths.googleAuth,
              key: result.user.uid,
            },
          },
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // sing in with apple
  const singInWithApple = () => {
    setForm((prevForm) => ({
      ...prevForm,
      mail: null,
      password: null,
    }));
    const provider = new OAuthProvider("apple.com");
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result);
        setForm((prevForm) => ({
          ...prevForm,
          auths: {
            ...prevForm.auths,
            appleAuth: {
              ...prevForm.auths.appleAuth,
              key: result.user.uid,
            },
          },
        }));
      })
      .catch((error) => {
        console.log(error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  };

  const LoginFunc = async (config) => {
    (form.auths.googleAuth.key === null && form.auths.appleAuth.key === null
      ? config ?? validateForm()
      : true) &&
      (await Axios.post(`${context.api_endpoint}/auth/staff`, config ?? form)
        .then(async (response) => {
          if (response.status === 201) {
            clearLocalStorage();
            emailRememberMe();
            const { data } = response;
            await AuthWithToken(data?.data?.token, context);
            config && (await props.history.push("/dashboard"));
          } else {
            toast.error(
              "İşlem gerçekleştirilemedi! Giriş bilgilerinizi kontrol ederek tekrar deneyiniz."
            );
          }
        })
        .catch((e) => {
          //Lisansı bittiyse
          if (e.response?.data?.status === 402) {
            setLicenseEnding({
              modal: true,
              company: { ...jwt_decode(e.response.data.data) }.obj.name,
            });
          } else {
            toast.error(e?.response?.data?.message);
          }
        }));
  };
  useEffect(() => {
    if (
      form.auths.googleAuth.key !== null ||
      form.auths.appleAuth.key !== null
    ) {
      LoginFunc();
      setForm((prevForm) => ({
        ...prevForm,
        mail: null,
        password: null,
        auths: {
          ...prevForm.auths,

          googleAuth: {
            key: null,
          },
          appleAuth: {
            key: null,
          },
        },
      }));
    }
  }, [form.auths]);
  const validateForm = () => {
    return ValidateLogin([
      {
        field: "E-Posta/Cep Telefonu",
        value: form.mail,
        condition: String,
        required: true,
        type: "email",
      },
      {
        field: "Şifre",
        value: form.password,
        condition: String,
        required: true,
        type: "password",
      },
    ]);
  };

  const emailRememberMe = () => {
    rememberMe
      ? localStorage.setItem("remember_me_email", form.mail)
      : localStorage.removeItem("remember_me_email");
  };

  return (
    <AuthContainer>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={8} className={classes.image} />

        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <div className={`${classes.paper}`}>
            <Typography
              component="div"
              className="mb-0"
              style={{ textAlign: "center" }}
            >
              {/* <b>{context.app.NAME}</b> */}
              <img
                src={require("../../assets/images/logo_enrandevu.png")}
                alt=""
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  height: "auto",
                  transform: "translateY(-30px)",
                }}
              />
            </Typography>
            {/* <Typography component="h1" variant="h6" className="mb-0">
              {context.t(`login.title`)}
            </Typography> */}

            <form
              className={`${classes.form}  pt-4`}
              noValidate
              onSubmit={(e) => e.preventDefault()}
            >
              <Input
                required
                label={context.t(`login.mailPhoneInputLabel`)}
                value={form.mail ? form.mail : ""}
                onChange={(e) =>
                  setForm({ ...form, mail: e.target.value.split(" ").join("") })
                }
              />

              <Input
                required
                label={context.t(`login.passInputLabel`)}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => {
                      setRememberMe(e.target.checked);
                    }}
                    name="checkedRememberMe"
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <span style={{ fontSize: "15px" }}>
                    {context.t(`login.rememberMe`)}
                  </span>
                }
              />

              <Button
                className="mt-2"
                title={context.t(`login.loginButtonTitle`)}
                type="submit"
                size="lg"
                fullWidth
                textColor="white"
                backgroundColor="primary"
                onClick={() => LoginFunc()}
              />
              <div className="login_btns_wrapper">
                <div
                  onClick={signInWithGoogle}
                  className="google_button_wrapper"
                >
                  <img src={googleIcon} alt="google-icon" />
                  <div>
                    <p> Google ile Giriş Yap</p>
                  </div>
                </div>
                <div
                  onClick={singInWithApple}
                  className="google_button_wrapper"
                >
                  <img
                    style={{ width: "21px" }}
                    src={appleIcon}
                    alt="google-icon"
                  />
                  <div>
                    <p>Apple ile Giriş Yap</p>
                  </div>
                </div>
              </div>

              <Button
                className="mt-1"
                title={context.t(`login.registerButtonTitle`)}
                type="submit"
                size="lg"
                fullWidth
                textColor="primary"
                backgroundColor="white"
                onClick={() => {
                  window.location.href = "https://enrandevu.com/uye-ol.php";
                }}
              />

              <Button
                title={context.t(`login.forgotPassButtonTitle`)}
                type="submit"
                size="lg"
                fullWidth
                textColor="teal"
                backgroundColor="white"
                onClick={() => props.history.push("/forgot-pass")}
              />
            </form>
          </div>
          <SmartBanner />

          {/*<LanguagesContainer>
                <LanguageButton heading="Türkçe" lang="tr" />
                <LanguageButton heading="English" lang="en" />
              </LanguagesContainer>*/}
        </Grid>
      </Grid>

      <LicenseEndingBackdrop className={license_ending.modal ? "show" : "hide"}>
        <LicenseEndingContainer
          className={license_ending.modal ? "show" : "hide"}
        >
          <i
            id="close-button"
            className="material-icons"
            onClick={() =>
              setLicenseEnding({ ...license_ending, modal: false })
            }
          >
            close
          </i>
          <h1>{context.t(`login.license.title`)}</h1>
          <p>
            {context.t(`login.license.description`, {
              company: license_ending.company,
            })}
          </p>
          <Button
            containerStyle={{ flexDirection: "column", height: "auto" }}
            className="mt-2"
            icon="call"
            title={context.t(`login.license.buttonTitle`)}
            backgroundColor="green-opacity"
            textColor="green"
            size="lg"
            onClick={() => window.open("tel:02127060510", "blank")}
          />
          <div className="mt-3">
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12} sx={{ margin: "0 auto" }}>
                <SinglePlan
                  type={context.t(`login.license.standart.type`)}
                  title={context.t(`login.license.standart.title`)}
                  price="199.00"
                  items={[
                    context.t(`login.license.standart.items[0]`),
                    context.t(`login.license.standart.items[1]`),
                    context.t(`login.license.standart.items[2]`),
                    context.t(`login.license.standart.items[3]`),
                    context.t(`login.license.standart.items[4]`),
                  ]}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
                <SinglePlan
                  type={context.t(`login.license.premium.type`)}
                  title={context.t(`login.license.premium.title`)}
                  price="149.00"
                  items={[
                    context.t(`login.license.premium.items[0]`),
                    context.t(`login.license.premium.items[1]`),
                    context.t(`login.license.premium.items[2]`),
                    context.t(`login.license.premium.items[3]`),
                    context.t(`login.license.premium.items[4]`),
                  ]}
                />
              </Grid> */}
            </Grid>
          </div>
        </LicenseEndingContainer>
      </LicenseEndingBackdrop>
    </AuthContainer>
  );
};

export default withRouter(LoginIndex);

const LicenseEndingBackdrop = styled.div`
  width: 100%;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -100vh;

  background: rgba(0, 0, 0, 0.16);

  visibility: hidden;
  opacity: 0;
  transition: all ease 0.4s;
  backdrop-filter: blur(15px);
  z-index: 9999;

  transition: opacity ease 0.5s, bottom ease 0.25s;

  &.show {
    visibility: visible;
    bottom: 0;
    opacity: 1;
  }
`;

const LicenseEndingContainer = styled.div`
  width: 1000px;
  max-width: 100%;
  max-height: 90vh;
  background: #fff;
  border-radius: 10px;
  padding: 48px;

  box-sizing: border-box;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.08);

  overflow-y: auto;

  display: flex;
  flex-direction: column;
  aling-items: center;
  justify-contetn: center;
  text-align: center;

  position: relative;
  opacity: 0;
  bottom: -50vh;

  &.show {
    bottom: 0;
    opacity: 1;
    transition: all ease 0.5s;
  }

  h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    line-height: 1.614;
  }

  i#close-button {
    font-size: 26px;
    padding: 24px;
    color: #000;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
