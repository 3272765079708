import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import AppContext from '../context/store';
import Button from '../theme/Button'
import { Grid, Column } from '../theme/Grid';


const getMobileOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) return "Android";
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
  return "";
}


const SmartBanner = () => {
  const context = useContext(AppContext);
  const [open, setOpen] = useState(true);

  return (
    <SmartBannerContainer className={`${open === false ? 'hide' : 'show'}`}>
      <CloseButton>
        <Button
          icon="highlight_off"
          iconSize={22}
          backgroundColor="transparent"
          textColor="white"
          size="sm"
          onClick={() => setOpen(false)}
        />
      </CloseButton>
      <BannerInner>
        <Grid className="no-gutters-all">
          <Column className="xs-4 sm-4" style={{ display: 'flex' }}>
            <PlatformImage src={
              require(
                getMobileOS() === 'Android'
                  ? '../assets/images/mobile/Android.png'
                  : '../assets/images/mobile/iOS.png'
              )}
            />
          </Column>

          <Column className="xs-8 sm-8">
            <MobileInner>
              <MobileTitle>{getMobileOS()} {context.t(`component.smartBanner.onAirText`)}</MobileTitle>
              <Button
                icon="shop"
                title="Uygulamayı İndir"
                backgroundColor="white"
                textColor={getMobileOS() === 'Android' ? "green" : "blue"}
                size="md"
                fullWidth
                onClick={
                  () => window.open(
                    getMobileOS() === 'Android'
                      ? "https://play.google.com/store/apps/details?id=com.en.randevu"
                      : "https://apps.apple.com/tr/app/enrandevu/id1551790908",
                    'blank'
                  )}
              />
            </MobileInner>
          </Column>
        </Grid>
      </BannerInner>
    </SmartBannerContainer>
  )
}

export default SmartBanner;

const SmartBannerContainer = styled.div`
  width: 100%;
  padding: 48px 24px 0;

  position: fixed;
  bottom: 0;
  z-index: 9000000000;
  color: #FFF;

  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0) 100%);
  transition: all ease 0.5s;

  &.show{bottom: 0; transition: all ease 0.5s;}
  &.hide{bottom: -200px; transition: all ease 0.5s;}

  @media only screen and (min-width: 576px) {
    display: none;
  }
`;

const BannerInner = styled.div`
  display: flex;
`

const PlatformImage = styled.img`
  width: 100%;
  max-height: 120px;
  object-fit: contain;
  margin-right: 16px;
`;

const MobileInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const MobileTitle = styled.h3`
  font-size: 18px;
  line-height: 1.2;
  margin-bottom: 16px;
  font-weight: 700;
  color: #FFF;
  text-shadow: 0 2px 4px rgba(0,0,0,0.4);
`;

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;