import React, { useEffect, useState, useContext } from "react";
import Navbar from "./component/Navbar";
import Header from "../Campaigns/Header/Header";
import Licences from "../Renewal/component/Licences";
import Statisctic from "../Campaigns/Statistics";
import SayAboutUs from "../Campaigns/SayAboutUs";
import UserMostLike from "../Campaigns/UserMostLike";
import ChatBox from "../Campaigns/components/ChatBox";
import Footer from "../Campaigns/Footer/Footer";
import { useParams } from "react-router-dom";
import Axios from "axios";
import AppContext from "../../../context/store";
import TimeIsUp from "../Campaigns/TimeIsUp/TimeIsUp";


function Renewal() {
  const [timeIsUP, setTimeIsUP] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [packages, setPackages] = useState([]);
  const { token } = useParams();
  const context = useContext(AppContext);

  useEffect(() => {
    if (token) {
      async function getRenewal() {
        await Axios.post(`${context.api_endpoint}/renewal`, {
          token,
          getPackets: true,
        })
          .then((data) => {
            setTimeLeft(Number(data.data.data.ttl));
            setPackages(data.data.data.campaignPackets);
          })
          .catch((err) => {
            if (err.response.status) {
              setTimeIsUP(true);
            }
          });
      }
      getRenewal();
    } else {
    }
  }, [token]);
  return !timeIsUP ? (
    <div style={{ position: "relative" }}>
      <ChatBox />
      <Navbar timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
      <Licences packages={packages} token={token} renewal={true} />
      <Header />
      <UserMostLike />
      <SayAboutUs />
      <Footer />
      <Statisctic />
    </div>
  ) : (
    <TimeIsUp />
  );
}

export default Renewal;
