import React, { useEffect } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import Select from "../../../theme/CustomMUI/Select";
import moment from "moment";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import Button from "../../../theme/Button";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import CalculateMessageCharCount from "../../../functions/CalculateMessageCharCount";
import Input from "../../../theme/CustomMUI/Input";
import CalculateMessageCount from "../../../functions/CalculateMessageCount";
import SMSReview from "../../../components/Dialogs/SMSReview";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import { FormControlLabel, Switch } from "@material-ui/core";
import FCLabel from "../../../theme/FCLabel";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";
import Loader from "../../../assets/images/circular.gif";

class CustomersRisky extends React.Component {
  static contextType = AppContext;

  state = {
    loaded: false,

    filter_expanded: false,
    offset: 30,

    pagination: {
      total_page: 1,
      page: 1,
      onChange: (page) => this.getRiskyCustomers(page),
    },
    data: [],

    start: new Date(),
    end: new Date(),
    filterPicker: [
      {
        id: 1,
        label: "Bugün",
        offset: 1,
      },
      {
        id: 2,
        label: "Bu Hafta",
        offset: 3,
      },
      {
        id: 3,
        label: "Bu Ay",
        offset: 4,
      },
    ],
    selected_filter_picker: null,

    selectedCustomers: [],
    selectedAllCustomer: false,
    exceptedCustomers: [],

    sms: {
      message: "",
      templates: [],
      selectedTemplate: null,
    },

    open_sms_dialog: false,

    open_sms_review_dialog: false,
    total_number: null,
    total_sms_credit: null,
  };

  getRiskyCustomers = (page = 1) => {
    const { filter_expanded, start, end, selected_filter_picker } = this.state;
    Axios.get(
      `${this.context.api_endpoint}/company/customers/risky`,
      filter_expanded
        ? selected_filter_picker === null
          ? {
              params: {
                page: page ? page : 1,
                ...{
                  start: moment(start).format("YYYY-MM-DD"),
                  end: moment(end).format("YYYY-MM-DD"),
                  isbetween: true,
                },
              },
            }
          : {
              params: {
                page: page ? page : 1,
                ...{
                  offset: selected_filter_picker.offset,
                  isbetween: false,
                },
              },
            }
        : {
            params: {
              page: page ? page : 1,
            },
          }
    )
      .then(({ data }) => {
        this.setState({
          loaded: true,
          data: [
            ...data.data.records.map((item) => {
              return {
                ...item,
                phone:
                  this.context.state.user.permission === 2 &&
                  this.context.state.limited_permission.customer_info === 0
                    ? phoneNumberDisplay(item.phone)
                    : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                        3,
                        6
                      )} ${item.phone.substring(6, 10)}`,
                extra_detail: {
                  ...item.extra_detail,
                  last_transaction_date: item.extra_detail.last_transaction_date
                    ? moment(item.extra_detail.last_transaction_date)
                        .startOf()
                        .fromNow()
                    : null,
                  sum_income: item.extra_detail.sum_income
                    ? item.extra_detail.sum_income.toFixed(2)
                    : "0.00",
                  total_debt: item.extra_detail.total_debt
                    ? item.extra_detail.total_debt.toFixed(2)
                    : "0.00",
                },
              };
            }),
          ],
          // pagination: {
          //   ...this.state.pagination,
          //   total_page: data.data.records.length,
          //   page: data.data.page,
          // },
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  getSMSReview = () => {
    Axios.post(
      `${this.context.api_endpoint}/company/customers/risky/sms/preview`,
      {
        all: this.state.selectedAllCustomer,
        customer_id: [...this.state.selectedCustomers],
        except: [...this.state.exceptedCustomers],
        message: this.state.sms.message,
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          await this.setState({
            total_number: response.data.data.total_numbers,
            total_sms_credit: response.data.data.total_sms_credit,
            open_sms_dialog: false,
            open_sms_review_dialog: true,
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  sendSMS = () => {
    Axios.post(
      `${this.context.api_endpoint}/company/customers/risky/send/sms`,
      {
        all: this.state.selectedAllCustomer,
        customer_id: [...this.state.selectedCustomers],
        except: [...this.state.exceptedCustomers],
        message: this.state.sms.message,
        offset: parseInt(this.state.offset),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            selectedCustomers: [],
            selectedAllCustomer: false,
            sms: {
              ...this.state.sms,
              message: "",
              selectedTemplate: null,
            },
          });
          toast.success("SMS başarıyla gönderildi.");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  componentDidMount() {
    this.getRiskyCustomers();
  }

  render() {
    const { loaded, offset } = this.state;

    return (
      <AuthContainer authorities={[1]} limited_permission="customer">
        <Grid className="mb-1">
          <Column className="xs-12 sm-12 md-12 lg-3">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={this.state.filter_expanded}
                  onChange={async (e) => {
                    await this.setState({
                      loaded: false,
                      filter_expanded: e.target.checked,
                    });
                    await this.getRiskyCustomers();
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>Tarih Bazlı Filtreleme</span>
                </FCLabel>
              }
            />
          </Column>
          {this.state.filter_expanded && (
            <>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`receipts.filter.startInputLabel`)}
                  value={new Date(moment(this.state.start).toISOString())}
                  maxDate={this.state.end}
                  onChange={async (start) => {
                    await this.setState({
                      loaded: false,
                      start,
                      selected_filter_picker: null,
                    });
                    await this.getRiskyCustomers();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <DatePickerInput
                  className="mb-0"
                  label={this.context.t(`receipts.filter.endInputLabel`)}
                  value={this.state.end}
                  minDate={new Date(moment(this.state.start).toISOString())}
                  onChange={async (end) => {
                    await this.setState({
                      loaded: false,
                      end,
                      selected_filter_picker: null,
                    });
                    await this.getRiskyCustomers();
                  }}
                />
              </Column>
              <Column className="xs-12 sm-12 md-12 lg-3">
                <Select
                  label={this.context.t(`appointments.dayWeekSelectLabel`)}
                  items={this.state.filterPicker}
                  labelKey="label"
                  valueKey="id"
                  selected={
                    this.state.selected_filter_picker
                      ? `${this.state.selected_filter_picker.id}`
                      : null
                  }
                  returnFullObject
                  handler={async (filterPicker) => {
                    await this.setState({
                      selected_filter_picker: filterPicker,
                    });
                    this.getRiskyCustomers();
                  }}
                />
              </Column>
            </>
          )}
          <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
            <Button
              title={
                this.state.selectedAllCustomer
                  ? this.context.t(`['customers/risky'].cancelAllButtonTitle`)
                  : this.context.t(`['customers/risky'].selectedAllButtonTitle`)
              }
              icon="check"
              backgroundColor={
                this.state.selectedAllCustomer ? "red" : "primary"
              }
              textColor="white"
              fullWidth
              onClick={() => {
                this.setState({
                  selectedCustomers: [],
                  selectedAllCustomer: !this.state.selectedAllCustomer,
                });
              }}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3 mb-1">
            <Button
              title={this.context.t(`['customers/risky'].sendSMSButtonTitle`)}
              icon="message"
              backgroundColor="blue-opacity"
              textColor="blue"
              fullWidth
              disabled={
                this.state.selectedAllCustomer
                  ? false
                  : this.state.selectedCustomers.length === 0
              }
              onClick={() => {
                this.setState({
                  open_sms_dialog: true,
                });
              }}
            />
          </Column>
          <Column
            className="xs-12 sm-12 md-12 lg-3"
            style={{ textAlign: "right" }}
          >
            <ExcelButton
              route="customers/risky"
              requestData={{ is_excel: true }}
              size="sm"
            />
          </Column>
        </Grid>
        {!loaded ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "5vh",
            }}
          >
            <img src={Loader} height={70} />
          </div>
        ) : (
          <Grid>
            <Column className="xs-12 sm-12">
              <Table
                refreshAction={() =>
                  this.getRiskyCustomers(this.state.pagination.page)
                }
                loaded={this.state.loaded}
                headings={{
                  full_name: {
                    label: this.context.t(
                      `['customers/risky'].headings.fullName`
                    ),
                    sortable: {
                      0: [
                        { field: "`customers`.`name`", order: "ASC" },
                        { field: "`customers`.`surname`", order: "ASC" },
                      ],
                      1: [
                        { field: "`customers`.`name`", order: "DESC" },
                        { field: "`customers`.`surname`", order: "DESC" },
                      ],
                    },
                  },
                  phone: {
                    label: this.context.t(`['customers/risky'].headings.phone`),
                    style: { width: 150 },
                  },
                  sex: {
                    label: this.context.t(`['customers/risky'].headings.sex`),
                  },
                  "extra_detail.sum_income": {
                    label: this.context.t(
                      `['customers/risky'].headings.incomeAll`
                    ),
                    suffix: this.context.state.currency
                      ? this.context.state.currency
                      : "₺",
                    sortable: {
                      0: [{ field: "`rp`.`amount`", order: "ASC" }],
                      1: [{ field: "`rp`.`amount`", order: "DESC" }],
                    },
                  },
                  "extra_detail.total_debt": {
                    label: this.context.t(
                      `['customers/risky'].headings.debtAll`
                    ),
                    suffix: this.context.state.currency
                      ? this.context.state.currency
                      : "₺",
                  },
                  "extra_detail.last_transaction_date": {
                    label: this.context.t(
                      `['customers/risky'].headings.lastTransaction`
                    ),
                  },
                  _: { label: this.context.t(`component.actionHeadingText`) },
                }}
                rows={this.state.data}
                replacements={{
                  sex: {
                    null: this.context.t(
                      `['customers/risky'].replacements.sex.undef`
                    ),
                    0: this.context.t(
                      `['customers/risky'].replacements.sex.female`
                    ),
                    1: this.context.t(
                      `['customers/risky'].replacements.sex.male`
                    ),
                    2: this.context.t(
                      `['customers/risky'].replacements.sex.undef`
                    ),
                  },
                }}
                pagination={this.state.pagination}
                selectedAll={this.state.selectedAllCustomer}
                excepteds={this.state.exceptedCustomers}
                checkedCheckboxes={this.state.selectedCustomers}
                checkboxOnChange={(row, state) => {
                  if (state) {
                    this.setState({
                      selectedCustomers: [
                        ...this.state.selectedCustomers,
                        row.id,
                      ],
                      exceptedCustomers: [
                        ...this.state.exceptedCustomers.filter(
                          (id) => id !== row.id
                        ),
                      ],
                    });
                  } else {
                    this.setState({
                      selectedCustomers: [
                        ...this.state.selectedCustomers.filter(
                          (id) => id !== row.id
                        ),
                      ],
                      exceptedCustomers: [
                        ...this.state.exceptedCustomers,
                        row.id,
                      ],
                    });
                  }
                }}
                buttons={[
                  {
                    title: this.context.t(`component.detailEditButtonTitle`),
                    icon: "launch",
                    textColor: "primary",
                    transitionEffect: true,
                    pushEffect: true,
                    onClick: (row) =>
                      this.props.history.push(`/customers/detail/${row.id}`),
                  },
                ]}
              />
            </Column>
          </Grid>
        )}
        {<this.MessageDialog />}
        <SMSReview
          open={this.state.open_sms_review_dialog}
          closeHandler={() => {
            this.setState({
              open_sms_review_dialog: false,
            });
          }}
          sendSMS={this.sendSMS}
          sms_content={this.state.sms.message}
          total_number={this.state.total_number}
          total_sms_credit={this.state.total_sms_credit}
        />
      </AuthContainer>
    );
  }

  MessageDialog = () => {
    const getTemplates = () => {
      Axios.get(`${this.context.api_endpoint}/company/sms/template/all`).then(
        ({ data }) => {
          this.setState({
            sms: {
              ...this.state.sms,
              templates: [...data.data],
            },
          });
        }
      );
    };

    useEffect(() => {
      getTemplates();
    }, []);

    return (
      <AlertDialog
        open={this.state.open_sms_dialog}
        title={this.context.t(`['customers/risky'].sendSMSButtonTitle`)}
        maxWidth="md"
        fullWidth={true}
        buttons={[
          {
            disabled:
              this.state.sms.message.length === 0 ||
              !CalculateMessageCount(this.state.sms.message),

            icon: "send",
            title: this.context.t(`sms.send.buttonTitle`),
            backgroundColor: "primary",
            textColor: "white",
            fullWidth: true,
            onClick: () => {
              this.getSMSReview();
            },
          },
          {
            icon: "close",
            title: this.context.t(`sms.send.discardButtonTitle`),
            backgroundColor: "red",
            textColor: "white",
            fullWidth: true,
            onClick: () => {
              this.setState({
                selectedCustomers: [],
                exceptedCustomers: [],
                selectedAllCustomer: false,
                sms: {
                  ...this.state.sms,
                  message: "",
                  selectedTemplate: null,
                },
              });
            },
          },
        ]}
        closeHandler={() => {
          this.setState({
            open_sms_dialog: false,
          });
        }}
      >
        <Select
          label={this.context.t(`sms.send.template`)}
          items={[...this.state.sms.templates]}
          selected={this.state.sms.selected_template?.name ?? ""}
          labelKey="name"
          valueKey="name"
          returnFullObject
          handler={(template) =>
            this.setState({
              sms: {
                ...this.state.sms,
                selectedTemplate: template,
                message: template.content,
              },
            })
          }
        />
        <Input
          label={this.context.t(`sms.send.messageInputLabel`)}
          multiline={true}
          rows={10}
          value={this.state.sms.message}
          onChange={(e) =>
            this.setState({
              sms: {
                ...this.state.sms,
                message: e.target.value,
              },
            })
          }
          helperText={CalculateMessageCharCount(this.state.sms.message, "1")}
        />
      </AlertDialog>
    );
  };
}

export default CustomersRisky;
