// ** Redux Imports
import reducers from "./rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import { configureStore } from "@reduxjs/toolkit";

const saga = createSagaMiddleware();
export const store = configureStore({
	reducer: reducers,
	middleware: [saga],
});

saga.run(rootSaga);

export const api_endpoint = "https://api.enrandevu.com/api/v1";
